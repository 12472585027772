module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1116750318430511"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-595009962","UA-73964235-1"]},
    },{
      plugin: require('../node_modules/gatsby-remark-google-analytics-track-links/gatsby-browser.js'),
      options: {"plugins":[],"target":"_blank","rel":"noopener noreferrer"},
    },{
      plugin: require('../node_modules/gatsby-remark-google-analytics-track-links/gatsby-browser.js'),
      options: {"plugins":[],"target":"_blank","rel":"noopener noreferrer","className":"_trackButton"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M3FHDJ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
